import Col from 'react-bootstrap/Col';

function Services() {
    return (
        <Col className="row serivce-row">
        <div className="col-12 col-sm-6 mb-4">
        <div className="card border-0 border-bottom border-primary shadow-sm">
          <div className="card-body">
          
            <Col>
             <img
      className="banner"
      src="assets/Items_0.webp"
      alt="First slide"
    />
            </Col>
            <Col className='card-info'>
            <h4 className="mb-2">College Admissions Counseling</h4>
            <p className="text-secondary alternative-cta-overline-wrapper"><div className="alternative-cta-pellet"></div>Guidance for students & graduates and their families on navigating the college admissions process, including college selection, application strategy, application editing, standardized test preparation, and financial aid assistance.
</p>
            <a href="/college_admissions_counseling" className="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
              </svg>
            </a>
            </Col>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-6 mb-4">
        <div className="card border-0 border-bottom border-primary shadow-sm">
          <div className="card-body">
          
            <Col>
             <img
      className="banner"
      src="assets/Items_1.webp"
      alt="First slide"
    />
            </Col>
            <Col className='card-info'>
            <h4 className="mb-2">Career Counseling</h4>
            <p className="text-secondary alternative-cta-overline-wrapper"> <div className="alternative-cta-pellet"></div>Assistance with career exploration, goal setting, resume building, interview preparation, and job search strategies for students and professionals at various stages of their careers in whichever country of choice.
</p>
            <a href="/career_counseling" className="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
              </svg>
            </a>
            </Col>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-6 mb-4">
        <div className="card border-0 border-bottom border-primary shadow-sm">
          <div className="card-body">
          
            <Col>
             <img
      className="banner"
      src="assets/Items_2.webp"
      alt="First slide"
    />
            </Col>
            <Col className='card-info'>
            <h4 className="mb-2">Academic Tutoring</h4>
            <p className="text-secondary alternative-cta-overline-wrapper"> <div className="alternative-cta-pellet"></div>Individualized tutoring services to help students improve their academic performance in specific subjects or standardized tests such as  IELTS or TOEFL.

</p>
            <a href="/academic_tutoring" className="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
              </svg>
            </a>
            </Col>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-6 mb-4">
        <div className="card border-0 border-bottom border-primary shadow-sm">
          <div className="card-body">
          
            <Col>
             <img
      className="banner"
      src="assets/Items_3.webp"
      alt="First slide"
    />
            </Col>
            <Col className='card-info'>
            <h4 className="mb-2">Guidance workshops</h4>
            <p className="text-secondary alternative-cta-overline-wrapper"> <div className="alternative-cta-pellet"></div>Workshops or coaching sessions to educate students the right choice of college or university within a student’s financial limits.

</p>
            <a href="/guidance_workshops" className="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
              </svg>
            </a>
            </Col>
          </div>
        </div>
      </div>


      {/* <div className="col-12 col-sm-6 mb-4">
        <div className="card border-0 border-bottom border-primary shadow-sm">
          <div className="card-body">
          
            <Col>
             <img
      className="banner"
      src="assets/Items_4.webp"
      alt="First slide"
    />
            </Col>
            <Col className='card-info'>
            <h4 className="mb-2">Parent Education Workshops</h4>
            <p className="text-secondary alternative-cta-overline-wrapper"> <div className="alternative-cta-pellet"></div>Workshops or seminars for parents on topics such as college planning, financial aid, parenting strategies, supporting academic success, and navigating the education system.

</p>
            <a href="#!" className="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
              </svg>
            </a>
            </Col>
          </div>
        </div>
      </div>
 */}

      <div className="col-12 col-sm-6 mb-4">
        <div className="card border-0 border-bottom border-primary shadow-sm">
          <div className="card-body">
          
            <Col>
             <img
      className="banner"
      src="assets/Items_5.webp"
      alt="First slide"
    />
            </Col>
            <Col className='card-info'>
            <h4 className="mb-2">College Placement Services</h4>
            <p className="text-secondary alternative-cta-overline-wrapper"> <div className="alternative-cta-pellet"></div>Assisting students & families with finding the right course or educational program.
</p>
            <a href="/college_placement_services" className="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
              </svg>
            </a>
            </Col>
          </div>
        </div>
      </div>

      <div className="col-12 col-sm-6">
        <div className="card border-0 border-bottom border-primary shadow-sm">
          <div className="card-body">
          
            <Col>
             <img
      className="banner"
      src="assets/Items_6.webp"
      alt="First slide"
    />
            </Col>
            <Col className='card-info'>
            <h4 className="mb-2">International Student Services</h4>
            <p className="text-secondary alternative-cta-overline-wrapper"> <div className="alternative-cta-pellet"></div>Guidance and support for international students seeking educational opportunities abroad, including college admissions counseling, visa assistance, cultural orientation,  academic support and part time job opportunities.</p>
            <a href="/international_student_services" className="fw-bold text-decoration-none link-primary">
              Learn More
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"></path>
              </svg>
            </a>
            </Col>
          </div>
        </div>
      </div>
        </Col>  
    )
}

export default Services;