import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './Pages/home';
import Admission from './Pages/college_admissions';
import Careercounseling from './Pages/career_counseling'
import Academic from './Pages/academic_tutoring'
import Guidance from './Pages/guidance_workshops'
import Placements from './Pages/college_placement'
import International from './Pages/international_student'
import Contactus from './Pages/contactus'
import MordoviaUniversity from "./Pages/mordovia_university"  


function Routers () {
      return (
        <Router>
          <Routes>
            <Route path="/" exact element={<Home/>}/>
            <Route path="/college_admissions_counseling" exact element={<Admission/>}/>
            <Route path="/career_counseling" exact element={<Careercounseling/>}/>
            <Route path="/academic_tutoring" exact element={<Academic/>}/>
            <Route path="/guidance_workshops" exact element={<Guidance/>}/>
            <Route path="/college_placement_services" exact element={<Placements/>}/>
            <Route path="/international_student_services" exact element={<International/>}/>
            <Route path="/Mordovia_University" exact element={<MordoviaUniversity/>}/>
            <Route path="/contact" exact element={<Contactus/>}/>
        </Routes>
    </Router>
      )
}
    
  
  export default Routers;