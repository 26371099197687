import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Contact from '../Components/contact';
import Carousel from 'react-bootstrap/Carousel';

function  Mordovia_University() {
    return (
        <div className='cont-12'>
             <Col className='innerbanner'>
            <Image src="assets/banner.webp" alt='banner.webp' fluid />
            <span className='_h1tag'>
                <h1>National Research Mordovia State University</h1>
                <p>Mordovia State University now holding a rank of a National research university.The University features a student population composed of over 13000 full timers, 8000 part timers and 240 evening students. There also are over 305 international students.</p>
            </span>
        </Col>
        <Container className='article_6'>
            <Row>
            <Col className="col-12 col-lg-7 col">
            <h5 className="H6">Why choose Mordovia ?</h5>
            <div class="col-md-12">
                <p>Mordovia State University now holding a rank of a National research university and it could also be a Federal state budgetary academic institution of higher education which present the whole spectrum of educational programmes for undergraduate (UG), graduate and post graduate (PG) students; among its main activity is prime and applied research, further training and qualification improvement courses for teaching staff and students. it's the main education institution within the Republic. By the federal edict dated of 20th May 2010 Ogarev Mordovia State University was conferred a category “National research university”.</p>
                <p>Founded in 1931 because the Agronomy Pedagogical Institute it gradually expanded and granted the University status in 1957. Its appearance was a milestone within the evolution of quality education within the Republic of Mordovia. the good majority (about 75%) of eligible Mordovian students attend Ogarev Mordovia State University. This determines its important role as a centre of education, research, culture and services for Mordovia and its people. Ogarev Mordovia State University is situated in city Saransk, capital of the Republic of Mordovia, 625 kilometers south east of Moscow. With its 30 academic buildings, 15 residence halls (10 for undergraduate students and four for graduate students and one academic staff) it is active in other Mordovian city where it's 2 Branch.</p>
                <p>Teaching staff is of some 1700 (13,6% of doctors, full professors and 67% of senior lecturers).</p>
                <p>Prospective students can choose between a spread of 135 available tracks of undergraduate and graduate study and concentration areas within full-time, part-time and evening programmes.</p>
                <p>The University (Ogarev Mordovia State University) features a student population composed of over 13000 full timers, 8000 part timers and 240 evening students. There also are over 305 international students. </p>
                <p>Full time curriculum covers 4 or 5-year periods of study counting on degree programme; evening and part-time education requires 6 years.</p>
                <p>Bachelor degrees are conferred upon completion of a 4-year period of study. Course duration for MBBS in Mordovia Medical Institute offers 5.8 and 7-year programmes of study respectively.</p>
                <p>The learning and teaching methods specialise in direct contact with students through lectures, workshops and practical classes. Throughout their course students are expected to finish theoretical and practical tasks and assignments and to undertake tests and final examinations at the top of every semester.</p>

            </div>
            </Col>
            <Col className='contactcontainer aboutcontact'>
                <h5 className="H5">Consult us Now</h5>
                <h6>Ready to embark on your educational journey with us? Contact us today</h6>
                    <Contact/>
                    <div class="col-md-12 margin8">
                    <Image src="assets/college01.webp" alt='college01.webp' fluid />
                    </div>
                  
                </Col>

                {/* 2. Student life */}
                <Col className='col-md-12 ullist'>
                     <h5 className="H6">Mordovia State University confers the subsequent academic qualifications</h5>
                     <summary>
                     <ul>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                    </svg>Bachelor Degree Diploma (4-year programme of study);</li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                    </svg>Diploma of upper Education (5-year programme of study);</li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                    </svg>Master Degree Diploma 2 years.</li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                    </svg>Diploma of PhD 1st level (further 3 years within the chosen field of study and thesis defended upon completion of a graduate study period);</li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                    </svg>Diploma of Doctor of Science (three more years of a postgraduate study after Candidate of science degree culminated in presenting dissertation).</li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                    </svg>After the completion of above respective programmes the graduate candidates receive a diploma approved by the Russian Federal Ministry of Science and Education.</li>
                        <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                    </svg>Duration of MBBS in Russia in English Medium is 5.8 years. Students can also choose MBBS in Russian Medium. Duration of which is 7 years which incorporates 1 year as Russian Language Training (Preparatory Faculty)</li>
                        </ul>
                     </summary>
                     <h5 className="H6">Student life</h5>
                     <p>Active, creative and anxious with research children are welcome to the scholars Council of the University. Throughout the tutorial year tons of educational , scientific, cultural, athletic, informational-analytical and socio-economical activities are organised by the Council. the scholars Council of the University was found out on the 15th of April 1987. It unites the scholars Councils of the schools , institutes and therefore the campus and operates as a self-government body within the University, facilitates the students' involvement within the managerial supervision over the tutorial activities, ensures the efficacy of the education, develops personal creativity in frames of curricular and scientific activities. the scholars Council implements and promotes the subsequent programmes: "University Festival of the scholars Creativity", "Students' Springtime", "Intellectual Creativity", "School of Camp Counsellors’ Excellence", "Prevention of Torts within the Students Community", Club of the Funny and Inventive, Festival of the youth sub-cultures "You Make Your Own Choice" and lots of other activities and contests. The scholars have an honest opportunity to develop their talents within the vocal and choreographic studios, intellectual and hiking clubs, School of the Youth Leaders Mastership, Club of the Funny and Inventive, games "What? Where? When?", "Brain-ring". an excellent number of the spectators are usually attracted by the annual festival Students' Spring and wonder contest "Sudarushka".</p>
                     <p>Sport plays an excellent role within the university life. the scholars are permanently participating and winning various contests in soccer, volleyball, basketball, field athletics and lots of other sport competitions. Sports facilities of the University include 4 gyms, 2 recreational complexes, 3 sports centre. In 2011 a university swimming bath was put into operation as a part of the project "500 Swimming Pools" facilitated by the party "United Russia". Traditional Sparta Games "Health" and Intercollegiate Student Games in 13 sorts of sports happen on the annual basis. Our students successfully participate and win prizes within the federal and international competitions. The University is pleased with its alumni – world famous athletes Olga Kaniskina, Sergey Kirdyapkin, Denis Nizhegorodov. The University team has twice won the Europian Universities Championship in volleyball. In 2010 and 2011 MRSU was the winner of the Russian-wide contest "University of the Healthy Life-Style". The University features a well developed social organization . It incorporate of the Department of Social Development, Centre for Students' Employment Assistance, Trade-Unions, athletic recreational complex, food production facility "youngester", polyclinics, recreational sanatorium, vacation house "Ogarev", sports and recreational complex "Rainbow-2" located at the Black Sea coast.</p>
                     <p>The cultural group of the Centre is organizing all-university activities, concerts, themed evenings, methodically facilitates the honouring activities, festival occasions, contests and festivals at the University, cooperates with the social and artistic bodies of Mordovia and Russia. the foremost bright festive occasions are the freshers rite of passage celebration "Welcome Fresher!" Mirthful activities and musicals dedicated to N.P.Ogarev's Birthday, entertainment shows dedicated to the scholars holiday "Tatiana's Day", the evenings of literature and music happening at the hostels, concert programmes on the Youth Day, City Day, Global Students' Day. the scholars never loose an opportunity to participate within the contest "Star-fall", poetic contest "To You My MSU!", festival of the amateur authors' songs, vocal contest "Night Gale Grove", patriotic contest "Bright Hawk", beauty contest "Miss University "Sudarushka", festival "Students Spring" etc. The Culture and humanities Centre hosts quite 30 artistic teams, studios and consolidated clubs.</p>
                     <p>Indian student celebrate all Indian festivals like Holi, Diwali, Durga Pooja, Eid, Bakrid, Ramazan.</p>  
                      <div className='lifepic'>
                           <Col><Image src="assets/life02.webp" alt='life02.webp' fluid /></Col>
                           <Col><Image src="assets/life03.webp" alt='life03.webp' fluid /></Col>
                           <Col><Image src="assets/life04.webp" alt='life04.webp' fluid /></Col>
                           <Col><Image src="assets/life05.webp" alt='life05.webp' fluid /></Col>
                      </div>
                </Col>



{/* 3. Indian Hostels
4. Indian & International relations */}
                <Col className='col-md-12 ullist'>
                <h5 className="H6">Mordovia State University confers the subsequent academic qualifications</h5>
                <p>International students can make enquiries on admissions policy altogether available tracks of study and pre-diploma programmes of study (Russian language course) before the first of August, the year proceeding the new school year (commencing 1st September).
                Formally the admission process starts together with your documentation being placed for review so as to form sure whether you're eligible or to not have studies at Mordovian State University. All applicants are required to undergo the international office electronically or by fax the copies of documents listed below so as to possess a student visa invitation issued
                </p>
                <ul>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>Your International passport;</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>School Certificate with academic results (SLC, A-level, HSSC or equivalent).</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>Your original school mark sheet with translation in Russian Language with Notary.</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>Immigration Card copy;</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>Certificate of equivalence for all academic documents;</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>AIDS testing report.</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>Originals of the above documentation together with passport, entry visa and migration card, personal dossier, 10 colour photos 3x4 are to be submitted to the International office upon arrival.</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>International applicants, eligible to use for degree programmes, are required to possess an interview in Russian in three disciplines counting on degree programme of his/her choice.</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>Enrolment for pre-diploma studies is predicated on submitted documentation only.</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>Before enrolment all international students are susceptible to pay:</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>The visa invitation fee (It takes 1 month to possess the invitation issued) – (25$); a multiple entry visa -30$</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>Mailing expenses of the visa invitation to the place of request – (50-100$);</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>Medical insurance for a free medical service at the students’ polyclinic – 100$ per annum .</li>
                <li><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bookmark-check-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M2 15.5V2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.74.439L8 13.069l-5.26 2.87A.5.5 0 0 1 2 15.5m8.854-9.646a.5.5 0 0 0-.708-.708L7.5 7.793 6.354 6.646a.5.5 0 1 0-.708.708l1.5 1.5a.5.5 0 0 0 .708 0z"/>
                </svg>International students are required to tell the International Office about the precise dates of their arrival a minimum of fortnight before this date.</li>
                </ul>
                </Col>
                <Col className='col-md-12 ullist'>
                <h5 className="H6">Hostels</h5>
                <p>MRSU welcomes international and non-local students and provides them with comfortable living conditions at 15 hostels. Eleven of them are located on campus. Hostels currently accommodate quite 5000 students. Two more de luxe hostel of residence were opened in 2018 to accommodate quite 1000 students. they're located in Moskovskaya and B. Khmelnitsky streets and offer comfortable rooms, each features a cable TV, a fridge, a shower cabin. Equipped with external video closed-circuit television and a card reading system on entry doors, it makes them the safest place for living. Among the facilities are computer classes with an online access, laundry rooms, performance halls and fitness rooms. Here you'll find all necessary conditions to pursue studies and have adequate rest. Accommodation fee is a smaller amount than 5 you look after regular scholarship. All international student can in the University hostel according to their choice. For Indian students have special Hostel “FRESCO” for 1st & 2nd year student for adaptation with special care. Saransk and Mordovia
                Mordovia may be a multinational republic. Throughout the centuries the Russians, Mordovian's and Tatars live here side by side. State languages are Russian, Erzya and Moksha. At the present years the republic is popping to the important centre of the Ugro-Finnish world. The Mordovian expats come here to review , work and live. In 2012 Russia celebrates 1000-year anniversary of the Republic of Mordovia.
                </p>
                </Col>     
                <Col className='col-md-12 ullist'>
                <h5 className="H6">Republic of Mordovia</h5>
                <p>
                Mordovia is situated within the centre of European Union a part of Russia at the intersection of the foremost important transport arteries connecting Moscow – Samara – Nizhniy Novgorod – Saratov. It borders the regions of Nizhniy Novgorod, Ryazan, Penza, Ulyanovsk and Chuvashia being a neighborhood of Volga administrative district . Population – 800 thousand people. The climate is temperate continental. At the junction of the woodland and steppe natural zones the rivers Moksha, Alatyr and Sura are flowing through Mary woods and vast fields. generally there are 114 large and little rivers and 500 lakes. In Mordovia there are pretty 100 areas of outstanding natural beauty including Simkin and park "Smolny".
                </p>
                </Col>
                <Col className='col-md-12 ullist'>
                <h5 className="H6">International Students</h5>
                <p>The outset of international co-operation of N.P. Ogarev Mordovia State University are often dated back to 1987. it's at the top of 80-ies - beginning of 90-ies that the university accepted several numerous groups of scholars from Afghanistan, Vietnam and India.
                By now quite 500 former graduates add different countries of the world: Australia, Algeria, Afghanistan, Bangladesh, Vietnam, Germany, Egypt, India, Jordan, Iraq, Iran, Spain, Yemen, Cameroon, Canada, China, Korea, Laos, Lebanon, Nepal, United Arab Emirates, Pakistan, USA, Syria, Soudan, France, Sri Lanka, and also the so called Soviet union countries: Armenia, Azerbaijan, Belarus, Kazakhstan, Latvia, Lithuania, Uzbekistan, Ukraine, Turkmenistan, Estonia.
                There are currently over 300 international students from 30 countries having classes at the varied faculties and institutes: hottest are the institute of drugs , national and folk culture institute, physics and chemistry institute, Automobile Engineering and power engineering institute, history and sociology institute, institute of mechanical engineering; law faculty, economics, foreign languages, architecture and construction engineering, geography, faculty of pre-diploma studies.</p>
                </Col>
                <Col className='col-md-12 ullist'>
                <h5 className="H6">Fee Structure of MBBS For Academic Year 2024-2025</h5>
                <div class="table-responsive">
                <table class="table table-bordered">
                <tbody>
                <tr class="main_heading">
                <td class="heading1 white_border">PARTICULARS</td>
                <td class="heading1 white_border">YEAR-1</td>
                <td class="heading1 white_border">YEAR-2</td>
                <td class="heading1 white_border">YEAR-3</td>
                <td class="heading1 white_border">YEAR-4</td>
                <td class="heading1 white_border">YEAR-5</td>
                <td class="heading1 white_border">YEAR-6</td>
                </tr>
                <tr class="row1">
                <td class="white_border">TUITION FEES</td>
                <td class="white_border"> 4,500$ USD </td>
                <td class="white_border"> 4,500$ USD </td>
                <td class="white_border"> 4,500$ USD </td>
                <td class="white_border"> 4,500$ USD </td>
                <td class="white_border"> 4,500$ USD </td>
                <td class="white_border"> 4,500$ USD </td>
                </tr>
                <tr class="row2">
                <td class="white_border">Hostel Fees</td>
                <td class="white_border"> 800$ USD </td>
                <td class="white_border"> 800$ USD </td>
                <td class="white_border"> 800$ USD </td>
                <td class="white_border"> 800$ USD </td>
                <td class="white_border"> 800$ USD </td>
                <td class="white_border"> 800$ USD </td>
                </tr>
                <tr class="row1">
                <td class="white_border">Medical Insurance &amp; Biometric</td>
                <td class="white_border"> 200$ USD </td>
                <td class="white_border"> 200$ USD </td>
                <td class="white_border"> 200$ USD </td>
                <td class="white_border"> 200$ USD </td>
                <td class="white_border"> 200$ USD </td>
                <td class="white_border"> 200$ USD </td>
                </tr>
                <tr class="row2">
                <td class="white_border">Administration Charges</td>
                <td class="white_border"> 1,500$ USD </td>
                <td class="white_border"> - </td>
                <td class="white_border"> - </td>
                <td class="white_border"> - </td>
                <td class="white_border"> - </td>
                <td class="white_border"> - </td>
                </tr>
                <tr class="row1">
                <td class="white_border">Total</td>
                <td class="white_border"> 7,000$ USD </td>
                <td class="white_border"> 5,100$ USD </td>
                <td class="white_border"> 5,100$ USD </td>
                <td class="white_border"> 5,100$ USD </td>
                <td class="white_border"> 5,100$ USD </td>
                <td class="white_border"> 5,100$ USD </td>
                </tr>
                </tbody>
                </table>
                </div>
                </Col>

                

<Col className='col-md-12 ullist testimonials'>
<h5 className="H6">Testimonials</h5>
 <Carousel className='banner' data-bs-theme="dark">
     <Carousel.Item>
        <Carousel.Caption>
          <h6><b>Dr. Adarsha Vadery, Kozhikode-</b> I am really thankful to Saintes and 
                New Era Kozhikode for giving me this oppurtunity to study in the 
                best university. The teachers and faculties are extremely supportive.</h6>
          
        </Carousel.Caption>
      </Carousel.Item>

        <Carousel.Item>
        <Carousel.Caption>
          <h6><b>Dr. Swathi Palathingal, Malappuram-</b> I joined the university in 2017. 
                Six years have been the best journey, Glad to meet the best team 
                who took care of us all through. The university listens to all our 
                concerns. We had the best medical practical experiences.</h6>
          
        </Carousel.Caption>
      </Carousel.Item>

        <Carousel.Item>
        <Carousel.Caption>
          <h6> <b>Dr. Afsal Kottayil, Malappuram -</b> The city and the university was very 
                humble to my education experience. Russians were very loving to us. 
                I travelled to many places in Russia, visited the northern lights. My 
                life in Mordovia was very memorable. </h6>
          
        </Carousel.Caption>
      </Carousel.Item>

        <Carousel.Item>
        <Carousel.Caption>
          <h6><b>Dr. Karthika Kurup, Pathanamthitta -</b> Teachers and faculties were the 
            best, they cleared all our queries and doubts, took all the effort to 
            give us time. Hospital practice memories is something I will never 
            forget</h6>
          
        </Carousel.Caption>
      </Carousel.Item>
 </Carousel>     

</Col>
<Col className='col-md-12 ullist contactdetails'>
<Col className="col-12 col-lg-7 col">
<h5 className="H6">University Address</h5>
<p>Bol'shevistskaya Ulitsa, 68, </p>
<p>Saransk, </p>
<p>Mordovia Republic, </p>
<p>Russia, 430005</p>
</Col>
<Col>


</Col>
</Col>
            </Row>
         </Container>   
        </div>

    )}

    export default  Mordovia_University;    