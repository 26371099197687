import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Contact from '../Components/contact';

function Collegeadmission() {
  return (
   <div className='cont-12'>
         <Col className='innerbanner'>
            <Image src="assets/Items_1.webp" fluid />
            <span className='_h1tag'>
                <h1>College Admissions Counseling</h1>
                <p>Guidance for students & graduates and their families on navigating the college admissions process, including college selection, application strategy, application editing, standardized test preparation, and financial aid assistance.
</p>
<a href='/contact' className="button-50" role="button" fdprocessedid="xdpexs">Contact Us</a>
            </span>
        </Col>
        <Container className='article_6'>
            <Row>
            <Col className="col-12 col-lg-7 col">
              <p> At Saintes, we understand that choosing the right career path can be a daunting task. That's why we offer comprehensive career counseling services to help individuals navigate their professional journey with confidence and clarity.</p>

                <h5 className="H6">Our Approach</h5>
                <p>Our experienced career counselors take a personalized approach to guide you through every step of the career planning process. Whether you're a recent graduate exploring your options or a seasoned professional seeking a career change, we're here to support you.</p>

                <h5 className="H6">What We Offer?</h5>
               <article>
               <p><b>Self-Assessment:</b> We begin by helping you understand your strengths, interests, values, and career goals through various assessment tools and exercises.</p>
                <p><b>Career Exploration:</b> We provide insights into different industries, job roles, and career paths to help you make informed decisions about your future.</p>
                <p><b>Goal Setting:</b> Together, we establish realistic and achievable career goals based on your skills, aspirations, and market trends.</p>
                <p><b>Resume and Cover Letter Review:</b> Our experts offer valuable feedback to optimize your resume and cover letter, ensuring they stand out to potential employers.</p>
                <p><b>Interview Preparation:</b> We equip you with the knowledge and skills needed to ace job interviews, including mock interviews, behavioral interviewing techniques, and confidence-building strategies.</p>
                <p><b>Job Search Strategies:</b> We assist you in developing effective job search strategies, leveraging networking opportunities, online resources, and job search platforms.</p>
                <h5 className="H6">Why Choose Us</h5>
                <p><b>Expert Guidance:</b> Our team of experienced career counselors are passionate about helping individuals reach their full potential and achieve career success.</p>
                <p><b>Personalized Approach:</b> We recognize that every individual is unique, which is why we tailor our counseling sessions to meet your specific needs and goals.</p>
                <p><b>Proven Results:</b> With a track record of success, we have helped countless individuals find fulfilling careers aligned with their interests and aspirations.</p>
               </article>
            </Col>
                <Col className='contactcontainer aboutcontact'>
                <h5 className="H5">Consult us Now</h5>
                <h6>Ready to embark on your educational journey with us? Contact us today</h6>
                    <Contact/>
                </Col>
            </Row>
        </Container>
   </div>
  );
}

export default Collegeadmission;