
import { useState } from "react";
import Gallery from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

// import optional lightbox plugins
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";


export const photos = [
  {src: "assets/compressed/53d2bc35-fd3d-492c-b14b-7c316460d41b_Original.webp", width: 1080, height: 800},
  {src: "assets/compressed/59487f1a-9b8e-4e2b-817a-b47061050761_Original.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6181.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6474.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6481.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6616.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6693.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6709.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6715.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6716.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6725.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6726.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6735.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6741.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6754.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6759.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6765.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6773.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6777.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6787.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6793.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6815.webp", width: 1080, height: 800},
  {src: "assets/compressed/IMG_6821.webp", width: 1080, height: 800},
  

 
];


export default function App() {
  
  const [index, setIndex] = useState(-1);
  return (
    <div>
      <Gallery layout="masonry" photos={photos}  targetRowHeight={150} onClick={({ index }) => setIndex(index)}/>
      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[ Slideshow]}
      />
    </div>
  );
}