import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Contact from '../Components/contact';
function Academictutoring() {
  return (
   <div className='cont-12'>
         <Col className='innerbanner'>
            <Image src="assets/Items_3.webp" fluid />
            <span className='_h1tag'>
                <h1>Academic Tutoring</h1>
                <p>Individualized tutoring services to help students improve their academic performance in specific subjects or standardized tests such as  IELTS or TOEFL.

</p><a href='/contact' className="button-50" role="button" fdprocessedid="xdpexs">Contact Us</a>
            </span>
        </Col>
        <Container className='article_6'>
            <Row>
            <Col className="col-12 col-lg-7 col">
               <p>
               Academic success is a journey that requires guidance, support, and personalized attention. Whether you're struggling with a challenging subject or aiming to excel in your studies, academic tutoring can provide the tools and resources you need to reach your full potential. 
               At Saintes, we are committed to empowering students of all ages to achieve academic excellence through individualized tutoring programs tailored to their unique learning styles and needs.
               </p>

                <h5 className="H6">Our Approach</h5>
                <p>
                At Saintes, we believe that every student has the ability to succeed with the right support and guidance. Our approach to academic tutoring is rooted in personalized learning, focusing on building confidence, reinforcing foundational skills, and fostering a deep understanding of subject matter concepts. Our experienced tutors work closely with each student to assess their strengths, identify areas for improvement, and develop customized tutoring plans to address their specific academic goals.
                </p>

                <h5 className="H6">What We Offer</h5>
               <article>
                <p><b>Subject-Specific Tutoring:</b>From mathematics and science to language arts and history, our tutors are proficient in a wide range of subjects, offering comprehensive tutoring support tailored to the needs of each student. </p>
                <p><b>Test Preparation:</b> Whether you're preparing for standardized tests such as the SAT, ACT, GRE, or professional certification exams, our tutors provide targeted instruction and practice to help you achieve your desired scores. </p>
                <p><b>Homework Help:</b> Our tutors offer assistance with homework assignments, projects, and study skills, providing valuable support to reinforce classroom learning and improve academic performance. </p>
                <p><b>Study Skills Development:</b> We teach essential study skills, time management techniques, and organizational strategies to help students become more effective learners and independent thinkers. </p>
                <p><b>Specialized Programs:</b> We offer specialized tutoring programs for students with learning differences, ADHD, dyslexia, and other unique needs, providing customized support and accommodations to promote academic success. </p>
               </article>
               <h5 className="H6">Why Choose Us</h5>
               <article>
               <p><b>Qualified Tutors:</b> Our tutors are highly qualified professionals with expertise in their respective subject areas and a passion for helping students succeed. </p>
               <p><b>Personalized Attention:</b> We provide individualized tutoring sessions tailored to each student's learning style, pace, and preferences, ensuring that they receive the attention and support they need to thrive academically. </p>
               <p><b>Flexible Scheduling:</b> We offer flexible scheduling options to accommodate busy student schedules, with in-person and online tutoring sessions available for added convenience. </p>
               <p><b>Proven Results:</b> With a track record of success, we have helped countless students improve their grades, boost their confidence, and achieve their academic goals. </p>
               </article>
              
            </Col>
                <Col className='contactcontainer aboutcontact'>
                <h5 className="H5">Consult us Now</h5>
                <h6>Ready to embark on your educational journey with us? Contact us today</h6>
                    <Contact/>
                </Col>
            </Row>
        </Container>
   </div>
  );
}

export default Academictutoring;