import Container from 'react-bootstrap/Container';
// import logo from '../logo.svg';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useEffect, useState } from "react";


function Header() {
  const urlWindow = window.location;
    const acceptedPaths = ["contact", "college_admissions_counseling", "career_counseling", "academic_tutoring", "guidance_workshops", "college_placement_services", "international_student_services","Mordovia_University"];

  const [scrolled, setScrolled] = useState(false);
  useEffect(() => {
    const listenToScroll = () => {
      if (window.pageYOffset > 50) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", listenToScroll);
    return () => window.removeEventListener("scroll", listenToScroll);
  }, []);

  return (
    <Navbar collapseOnSelect expand="lg" fixed="top" className={"bg-body-tertiary " + (scrolled ? "affix" : "")}>
      <Container fluid>
        <Navbar.Brand href="/"><Image src="/logo.svg" width='280' height="auto" className="img-fluid" alt="logo"/></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        {acceptedPaths.includes(urlWindow.pathname.split("/")[1]) ? (
                            <Nav className="me-auto my-2 my-lg-0">
                            <Nav.Link href="/">Home</Nav.Link>
                            <Nav.Link href="/">About</Nav.Link>
                            <NavDropdown title="Our Services" className='dropdownbox' id="collapsible-nav-dropdown">
                              <NavDropdown.Item href="/college_admissions_counseling"> 
                              <h2>College Admissions Counseling</h2>
                              <p>Guidance for students & graduates and their families on navigating the college admissions process, including college selection, application strategy, application editing, standardized test preparation, and financial aid assistance.</p>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/career_counseling"> 
                              <h2>Career Counseling</h2>
                              <p>Assistance with career exploration, goal setting, resume building, interview preparation, and job search strategies for students and professionals at various stages of their careers in whichever country of choice.</p>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/academic_tutoring">
                              <h2>Academic Tutoring</h2>
                              <p>Individualized tutoring services to help students improve their academic performance in specific subjects or standardized tests such as IELTS or TOEFL.</p>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/guidance_workshops"> 
                              <h2>Guidance workshops</h2> 
                              <p>Workshops or coaching sessions to educate students the right choice of college or university within a student’s financial limits.</p>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/college_placement_services">  
                              <h2>College Placement Services</h2> 
                              <p>Assisting students & families with finding the right course or educational program.</p>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/international_student_services"> 
                              <h2>International Student Services</h2>
                              <p>Guidance and support for international students seeking educational opportunities abroad, including college admissions counseling, visa assistance, cultural orientation, academic support and part time job opportunities.</p>
                               </NavDropdown.Item>
                            </NavDropdown>
                            {/* <NavDropdown title="University" className='dropdownbox universityDropdown' id="collapsible-dropdown">
                              <NavDropdown.Item href="/Mordovia_University"> 
                              <h2>National Research Mordovia State University</h2>
                              <p>Admissions 2024 for MBBS is now open</p>
                              <a href="/Mordovia_University" class="button-50" role="button">Check Now</a>
                              </NavDropdown.Item>
                            </NavDropdown> */}
                            <Nav.Link  href="/contact">Contact</Nav.Link>
                           
                          </Nav>
                        ) : 
                        (<Nav className="me-auto my-2 my-lg-0">
                        <Nav.Link href="#aboutus">About</Nav.Link>
                        <Nav.Link href="#whatwedo">What we do?</Nav.Link>
                        <NavDropdown title="Our Services" className='dropdownbox' id="collapsible-nav-dropdown">
                              <NavDropdown.Item href="/college_admissions_counseling"> 
                              <h2>College Admissions Counseling</h2>
                              <p>Guidance for students & graduates and their families on navigating the college admissions process, including college selection, application strategy, application editing, standardized test preparation, and financial aid assistance.</p>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/career_counseling"> 
                              <h2>Career Counseling</h2>
                              <p>Assistance with career exploration, goal setting, resume building, interview preparation, and job search strategies for students and professionals at various stages of their careers in whichever country of choice.</p>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/academic_tutoring">
                              <h2>Academic Tutoring</h2>
                              <p>Individualized tutoring services to help students improve their academic performance in specific subjects or standardized tests such as IELTS or TOEFL.</p>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/guidance_workshops"> 
                              <h2>Guidance workshops</h2> 
                              <p>Workshops or coaching sessions to educate students the right choice of college or university within a student’s financial limits.</p>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/college_placement_services">  
                              <h2>College Placement Services</h2> 
                              <p>Assisting students & families with finding the right course or educational program.</p>
                              </NavDropdown.Item>
                              <NavDropdown.Item href="/international_student_services"> 
                              <h2>International Student Services</h2>
                              <p>Guidance and support for international students seeking educational opportunities abroad, including college admissions counseling, visa assistance, cultural orientation, academic support and part time job opportunities.</p>
                               </NavDropdown.Item>
                            </NavDropdown>
                            <NavDropdown title="University" className='dropdownbox universityDropdown' id="collapsible-dropdown">
                              <NavDropdown.Item href="/Mordovia_University"> 
                              <h2>National Research Mordovia State University</h2>
                              <p>Admissions 2024 for MBBS is now open</p>
                              <a href="/Mordovia_University" class="button-50" role="button">Check Now</a>
                              </NavDropdown.Item>
                            </NavDropdown>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                        <Nav.Link href="#ourteam">Team</Nav.Link>
                      </Nav>
                        )
                        }
        
          <Nav className='_darkblue'>
            <Nav.Link href="mailto:contact@saintes.in"><svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-envelope-at-fill" viewBox="0 0 16 16">
  <path d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671"/>
  <path d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791"/>
</svg> contact@saintes.in</Nav.Link>
            <Nav.Link eventKey={2} href="tel:7034241124">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-phone-fill" viewBox="0 0 16 16">
  <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0"/>
</svg> +91 7034241124
            </Nav.Link>
            <div className="footer-social-icon">
                                {/* <span>Follow us</span> */}
                                <a href="https://www.facebook.com/profile.php?id=61557282710317" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/saintesedco"  target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" className="bi bi-twitter" viewBox="0 0 32 32" version="1.1">
                                        <g id="surface1"><rect x="0" y="0" width="32" height="32"/> <path d="M 12.003906 6.125 L 5.1875 6.125 L 13.265625 16.796875 L 5.703125 25.75 L 9.199219 25.75 L 14.917969 18.980469 L 19.996094 25.6875 L 26.8125 25.6875 L 18.496094 14.707031 L 18.511719 14.722656 L 25.671875 6.25 L 22.175781 6.25 L 16.859375 12.542969 Z M 8.949219 7.992188 L 11.070312 7.992188 L 23.050781 23.820312 L 20.929688 23.820312 Z M 8.949219 7.992188 "/></g>
                                </svg>
                                </a>
                                <a href="https://www.instagram.com/sainteseducation/"  target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                    </svg>
                                </a>

                                <a href="https://www.linkedin.com/in/saintes-education-consultants-1721212b8"  target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                    </svg>
                                </a>
                            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;