import Carousel from 'react-bootstrap/Carousel';

function Banner() {
  return (
    <Carousel className='banner' data-bs-theme="dark">
     <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/college01.webp"
          alt="First slide"
        />
        <Carousel.Caption>
          <h1>Admissions Open Now @ National Research Mordovia State University!</h1>
          <h6>Admissions to National Research Mordovia State University 2024 for MBBS is now open</h6>
          <a href="#contact" className="button-50" role="button">Consult us Now</a>
        </Carousel.Caption>
      </Carousel.Item>
     <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/Items_0.webp"
          alt="First slide"
        />
        <Carousel.Caption>
          <h1>Education towards Elevation!</h1>
          <h6>Saintes have dedicated to empower students to achieve their highest potential With a help of experienced professionals Team.</h6>
          <a href="#contact" className="button-50" role="button">Consult us Now</a>
        </Carousel.Caption>
      </Carousel.Item>

      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/Items_1.webp"
          alt="First slide"
        />
        <Carousel.Caption>
           <h5>College Admissions Counseling Services</h5>
           <h6>Gain admission to your top-choice College with Saintes.</h6>
          <a href="#contact" className="button-50" role="button">Consult us Now</a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/Items_2.webp"
          alt="Second slide"
        />
        <Carousel.Caption>
          <h5>Career Counseling</h5>
          <h6>Charting Your Career: Professional Counseling Services with Saintes.</h6>
          <a href="#contact" className="button-50" role="button">Consult us Now</a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/Items_3.webp"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Academic Tutoring</h5>
          <h6>Academic Ace: Personalized Tutoring Services with Saintes.</h6>
          <a href="#contact" className="button-50" role="button">Consult us Now</a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/Items_4.webp"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>Guidance workshops</h5>
          <h6>EmpowerED: Guidance Workshops for Personal and Academic Success with Saintes.</h6>
          <a href="#contact" className="button-50" role="button">Consult us Now</a>
        </Carousel.Caption>
      </Carousel.Item>
     
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/Items_5.webp"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>College Placement Services</h5>
          <h6>Saintes guide students toward their ideal academic destinations.</h6>
          <a href="#contact" className="button-50" role="button">Consult us Now</a>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src="assets/Items_6.webp"
          alt="Third slide"
        />
        <Carousel.Caption>
          <h5>International Student Services</h5>
         <h6> Saintes is dedicated to supporting students to navigating the complexities of studying abroad</h6>
         
  
          <a href="#contact" className="button-50" role="button">Consult us Now</a>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default Banner;