import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Contact from '../Components/contact';

function Collegeplacement() {
  return (
   <div className='cont-12'>
         <Col className='innerbanner'>
            <Image src="assets/Items_5.webp" fluid />
            <span className='_h1tag'>
                <h1>College Placement Services</h1>
                <p>Assisting students & families with finding the right course or educational program.</p>
                <a href='/contact' className="button-50" role="button" fdprocessedid="xdpexs">Contact Us</a>
            </span>
        </Col>
        <Container className='article_6'>
            <Row>
            <Col className="col-12 col-lg-7 col">
               <p>
               Navigating the college admissions process can be overwhelming, with countless decisions to make and numerous deadlines to meet. That's where our College Placement Services come in. At Saintes, we are committed to guiding students and families through every step of the college application journey, helping them find the perfect fit for their academic and personal goals.
               </p>

                <h5 className="H6">Our Approach</h5>
                <p>
                Our College Placement Services take a personalized approach, recognizing that every student is unique with their own strengths, interests, and aspirations. We work closely with each student to understand their academic background, extracurricular activities, career aspirations, and personal preferences to develop a customized college search and application strategy.
                </p>
                <h5 className="H6">What We Offer</h5>
               <article>
             <p><b>College Search and Selection:</b> We assist students in identifying colleges and universities that align with their academic interests, career goals, and personal preferences. We provide comprehensive information on factors such as academic programs, campus culture, location, and financial aid opportunities to help students make informed decisions.</p>
             <p><b>Application Assistance:</b> From crafting standout personal statements and essays to completing college applications and submitting required materials, we provide hands-on support throughout the application process to ensure that every application reflects the student's unique strengths and qualities.</p>
             <p><b>Test Preparation:</b> We offer tailored test preparation programs for standardized tests such as the SAT and ACT, helping students maximize their scores and increase their chances of admission to their dream colleges.</p>
             <p><b>Interview Preparation:</b> Our interview preparation workshops and coaching sessions equip students with the skills and confidence to ace college admissions interviews, highlighting their strengths and accomplishments effectively.</p>
             <p><b>Financial Aid and Scholarship Guidance:</b> We provide guidance on navigating the financial aid process, including completing the FAFSA, understanding financial aid packages, and exploring scholarship opportunities to make college more affordable for students and families.</p>
               </article>
               <h5 className="H6">Why Choose Us</h5>
               <article>
             <p><b>Expertise and Experience:</b> Our team of college placement counselors are seasoned professionals with extensive experience in college admissions and counseling. We stay updated on the latest trends and developments in higher education to provide informed guidance and support.</p>
             <p><b>Personalized Attention:</b> We take a student-centered approach, providing individualized attention and support to each student throughout the college application process. Our counselors are accessible and responsive, addressing students' questions and concerns promptly.</p>
             <p><b>Proven Track Record:</b> We have a track record of success, with many of our students gaining admission to top colleges and universities across the country. Our comprehensive approach to college placement has helped countless students achieve their academic and career goals.</p>
               </article>
              
            </Col>
                <Col className='contactcontainer aboutcontact'>
                <h5 className="H5">Consult us Now</h5>
                <h6>Ready to embark on your educational journey with us? Contact us today</h6>
                    <Contact/>
                </Col>

               
            </Row>
        </Container>
   </div>
  );
}

export default Collegeplacement;