import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Contact from '../Components/contact';

function Internationalstudent() {
  return (
   <div className='cont-12'>
         <Col className='innerbanner'>
            <Image src="assets/Items_6.webp" fluid />
            <span className='_h1tag'>
                <h1>International Student Services</h1>
                <p> Guidance and support for international students seeking educational opportunities abroad, including college admissions counseling, visa assistance, cultural orientation,  academic support and part time job opportunities.
</p><a href='/contact' className="button-50" role="button" fdprocessedid="xdpexs">Contact Us</a>
            </span>
        </Col>
        <Container className='article_6'>
            <Row>
            <Col className="col-12 col-lg-7 col">
               <p>
               Studying abroad is an exciting and transformative experience, but it also comes with its unique set of challenges. At Saintes, we understand the complexities that international students face when pursuing their academic goals in a foreign country. That's why we offer comprehensive International Student Services to support students every step of the way, ensuring a smooth transition and a successful academic journey.
               </p>

                <h5 className="H6">Our Commitment</h5>
                <p>
                At Saintes, we are committed to providing international students with the resources, assistance, and guidance they need to thrive in their new academic environment. Whether you're a prospective student exploring study abroad options or a current student adjusting to life in a new country, our dedicated team of professionals is here to help you navigate the challenges and maximize your opportunities.
                </p>

                <h5 className="H6">What We Offer</h5>
               <article>
             <p><b>Admissions Assistance:</b> Our International Student Services team provides personalized assistance with the admissions process, including guidance on application requirements, deadlines, and visa procedures. We help students navigate the complexities of the admissions process and ensure they meet all necessary criteria for enrollment.</p>
             <p><b>Orientation Programs:</b> We offer comprehensive orientation programs to help international students acclimate to their new surroundings and adjust to life on campus. From information sessions and campus tours to cultural workshops and social events, our orientation programs provide valuable insights and resources to facilitate a smooth transition.</p>
             <p><b>Immigration Support:</b> Our experienced advisors provide guidance and support with immigration-related matters, including visa applications, visa extensions, and compliance with immigration regulations. We keep students informed about their rights and responsibilities as international students and assist them in navigating the legal aspects of studying abroad.</p>
             <p><b>Academic Support:</b> We offer academic advising services to help international students succeed academically and achieve their academic goals. Our advisors provide guidance on course selection, academic planning, and study strategies, ensuring that students receive the support they need to excel in their studies.</p>
             <p><b>Cultural Integration:</b> We organize cultural events, workshops, and activities to promote cross-cultural understanding and facilitate connections between international students and the local community. We create opportunities for cultural exchange, collaboration, and friendship, enriching the academic experience for all students.</p>
               </article>
               <h5 className="H6">Why Choose Us</h5>
               <article>
             <p><b>Expertise and Experience:</b> Our International Student Services team comprises experienced professionals with expertise in international education and cross-cultural communication. We leverage our knowledge and experience to provide high-quality support and assistance to international students.</p>
             <p><b>Personalized Attention:</b> We take a student-centered approach, providing individualized attention and support to each student based on their unique needs and circumstances. We strive to create a supportive and inclusive environment where all students feel valued and respected.</p>
             <p><b>Proven Track Record:</b> From admissions assistance to immigration support and cultural integration, we offer a comprehensive range of services to meet the diverse needs of international students. We are committed to ensuring that students have access to the resources and support they need to thrive academically and personally.</p>
               </article>
              
            </Col>
                <Col className='contactcontainer aboutcontact'>
                <h5 className="H5">Consult us Now</h5>
                <h6>Ready to embark on your educational journey with us? Contact us today</h6>
                    <Contact/>
                </Col>
            </Row>
        </Container>
   </div>
  );
}

export default Internationalstudent;