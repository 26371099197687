import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Contact from '../Components/contact';


function Careercounseling() {
  return (
   <div className='cont-12'>
         <Col className='innerbanner'>
            <Image src="assets/Items_2.webp" fluid />
            <span className='_h1tag'>
                <h1>Career Counseling</h1>
                <p>Assistance with career exploration, goal setting, resume building, interview preparation, and job search strategies for students and professionals at various stages of their careers in whichever country of choice.

</p><a href='/contact' className="button-50" role="button" fdprocessedid="xdpexs">Contact Us</a>
            </span>
        </Col>
        <Container className='article_6'>
            <Row>
            <Col className="col-12 col-lg-7 col">
               <p>
               Embarking on a career path is a significant decision that can shape your future and define your success. However, navigating the complexities of the job market, identifying your strengths and passions, and making informed career choices can be overwhelming. This is where career counseling comes in—a valuable resource designed to empower individuals in their professional journey.
               </p>

                <h5 className="H6">What is Career Counseling?</h5>
                <p>
                Career counseling is a collaborative process between a qualified counselor and an individual seeking guidance and support in their career development. It involves exploring one's interests, skills, values, and personality traits to make informed decisions about education, training, and employment opportunities. Career counselors provide personalized assistance, helping individuals identify their career goals, overcome obstacles, and develop strategies for success.
                </p>

                <h5 className="H6">The Benefits of Saintes Career Counseling</h5>
               <article>
            <p><b>Self-Discovery:</b>Career counseling helps individuals gain insight into their strengths, interests, and values, leading to greater self-awareness and clarity about their career path. </p>
            <p><b>Exploration of Options:</b>Through assessments, research, and discussions with a counselor, individuals can explore a wide range of career options and make informed decisions about their future. </p>
            <p><b>Goal Setting:</b> Career counselors assist individuals in setting realistic and achievable career goals, creating actionable plans to help them reach their objectives. </p>
            <p><b>Skill Development:</b> Career counseling may include opportunities for skill assessment and development, helping individuals enhance their abilities and qualifications to succeed in their chosen field. </p>
            <p><b>Job Search Support:</b> From resume writing and interview preparation to networking strategies and job search techniques, career counselors provide valuable guidance and resources to help individuals navigate the job search process effectively. </p>
            <p><b>Career Transition Assistance:</b> Whether transitioning to a new industry, returning to the workforce after a hiatus, or pursuing entrepreneurship, career counseling offers support and guidance during periods of change and transition. </p>
            <p><b> Confidence Building:</b> Working with a career counselor can boost confidence and self-assurance, empowering individuals to pursue their career goals with conviction and resilience. </p>
               </article>
               <h5 className="H6">How Saintes Career Counseling Works</h5>
               <article>
               <h6>Career counseling sessions typically involve a series of structured discussions and activities tailored to the individual's needs and objectives. These may include:</h6>
           <p><b>Assessment:</b> Identifying strengths, interests, values, and personality traits through standardized assessments and exercises. </p>
           <p><b>Exploration:</b> Researching and exploring various career options, industries, and educational pathways. </p>
           <p><b>Goal Setting:</b> Establishing short-term and long-term career goals based on the individual's aspirations and capabilities. </p>
           <p><b>Action Planning:</b> Developing strategies and action plans to achieve career goals, including skill development, networking, and job search strategies. </p>
           <p><b>Follow-Up:</b> Ongoing support and follow-up sessions to monitor progress, address challenges, and adjust plans as needed. </p>
               </article>
               <h5 className="H6">Why Choose Saintes Career Counseling?</h5>
               <article>
               <h6>Career counseling offers invaluable benefits for individuals at various stages of their professional journey:</h6>
            <p><b>Guidance from Experts:</b> Career counselors are trained professionals with expertise in career development, providing knowledgeable guidance and support. </p>
            <p><b>Objective Perspective:</b> Career counselors offer an objective viewpoint, helping individuals gain clarity and perspective on their career options and decisions. </p>
            <p><b>Tailored Solutions:</b> Career counseling is personalized to each individual's needs, preferences, and circumstances, ensuring that solutions are relevant and effective. </p>
            <p><b>Empowerment:</b> By equipping individuals with the tools, knowledge, and confidence to make informed career choices, career counseling empowers them to take control of their professional destiny. </p>
               </article>
            </Col>
                <Col className='contactcontainer aboutcontact'>
                <h5 className="H5">Consult us Now</h5>
                <h6>Ready to embark on your educational journey with us? Contact us today</h6>
                    <Contact/>
                </Col>
            </Row>
        </Container>
   </div>
  );
}

export default Careercounseling;