import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Contact from '../Components/contact';
function Guidanceworkshops() {
  return (
   <div className='cont-12'>
         <Col className='innerbanner'>
            <Image src="assets/Items_4.webp" fluid />
            <span className='_h1tag'>
                <h1>Guidance workshops</h1>
                <p>Workshops or coaching sessions to educate students the right choice of college or university within a student’s financial limits.

</p><a href='/contact' className="button-50" role="button" fdprocessedid="xdpexs">Contact Us</a>
            </span>
        </Col>
        <Container className='article_6'>
            <Row>
            <Col className="col-12 col-lg-7 col">
               <p>
               Guidance workshops are invaluable resources designed to equip individuals with the knowledge, skills, and strategies needed to navigate various aspects of life successfully. At Saintes, we offer a range of dynamic and interactive guidance workshops tailored to address common challenges and empower participants to lead fulfilling lives.
               </p>

                <h5 className="H6">What to Expect</h5>
                <p>
                Our guidance workshops cover a diverse array of topics, each aimed at providing practical insights and actionable strategies for personal and professional growth. Whether you're looking to enhance your communication skills, improve time management, or cultivate a positive mindset, our workshops offer something for everyone.
                </p>

                <h5 className="H6">Workshop Topics</h5>
               <article>
               <p><b>Effective Communication: </b>Learn how to communicate with confidence, clarity, and impact in both personal and professional settings. Our workshop covers essential communication techniques, active listening skills, and strategies for resolving conflicts constructively.</p>
               <p><b>Stress Management:</b>Discover practical tools and techniques for managing stress, overcoming burnout, and fostering resilience in the face of life's challenges. Our workshop explores stress reduction strategies, relaxation techniques, and mindfulness practices to promote overall well-being.</p>
               <p><b>Goal Setting and Achievement:</b>Set yourself up for success by mastering the art of goal setting and achievement. Our workshop guides participants through the process of setting SMART goals, creating action plans, and staying motivated to achieve their aspirations.</p>
               <p><b>Career Development:</b>Whether you're exploring career options, seeking advancement opportunities, or considering a career change, our career development workshop provides valuable insights and resources to help you take the next step in your professional journey.</p>
               <p><b>Financial Literacy:</b>Gain essential knowledge and skills to manage your finances effectively and build a secure financial future. Our workshop covers budgeting, saving, investing, debt management, and financial planning strategies tailored to your individual goals and circumstances.</p>
               </article>
               <h5 className="H6">Why Choose Our Workshops</h5>
               <article>
              <p><b>Expert Facilitators:</b>Our workshops are facilitated by experienced professionals with expertise in their respective fields, ensuring high-quality instruction and engaging learning experiences.</p>
              <p><b>Interactive Format:</b> We believe in interactive learning that encourages active participation, discussion, and hands-on activities to enhance understanding and retention of key concepts.</p>
              <p><b>Practical Strategies:</b> Our workshops focus on providing practical, actionable strategies that participants can implement immediately to see positive results in their lives.</p>
              <p><b>Supportive Environment:</b> We create a supportive and inclusive environment where participants feel comfortable sharing their experiences, asking questions, and collaborating with others.</p>
               </article>
              
            </Col>
                <Col className='contactcontainer aboutcontact'>
                <h5 className="H5">Consult us Now</h5>
                <h6>Ready to embark on your educational journey with us? Contact us today</h6>
                    <Contact/>
                </Col>
            </Row>
        </Container>
   </div>
  );
}

export default Guidanceworkshops;