import FloatingLabel from 'react-bootstrap/FloatingLabel';
import React, {useRef} from 'react';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Contactform = () => {
  const ConctactForm = useRef();


  const SendEmail = (e) => {
    e.preventDefault();
 
    const { username, email, message, service, mobile } = ConctactForm.current.elements;

    emailjs.sendForm('service_fxui6u9', 'template_1lr1v29', ConctactForm.current,  'AMUJa1MHFC0QU1RKg')
      .then((result) => {
          toast.success("Hi Your Message Successfully! Our team will contact you soon.");
          console.log(result.text)
      }, (error) => {
     
          toast.error("Some technical error occurred. Please try again after sometime / contact@saintes.in ");
          console.log(error)
      });
  };

  return (
      <div className='formcontainer'>
     
     
      <Form className='querycontainer' id='Enquiryform' ref={ConctactForm}  onSubmit={SendEmail}>
          <FloatingLabel controlId="floatingInput" label="Name" className="mb-3">
                  <Form.Control type="text" name="username"  placeholder="Name" required/>
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Email address" className="mb-3">
              <Form.Control type="email" name="email" placeholder="name@example.com" required/>
          </FloatingLabel>
          <FloatingLabel controlId="floatingInput" label="Mobile No." className="mb-3">
              <Form.Control type="" name="mobile" placeholder="Enter your Mobile no." required/>
          </FloatingLabel>
          <FloatingLabel controlId="floatingSelect" label="Select your Services" className="mb-3">
              <Form.Select aria-label="Floating label select example" name='service' required>
              <option value="1">College Admissions Counseling</option>
              <option value="2">Career Counseling</option>
              <option value="3">Academic Tutoring</option>
              <option value="3">Guidance workshops</option>
              <option value="3">College Placement Services</option>
              <option value="3">International Student Services</option>
              </Form.Select>
          </FloatingLabel>
          <FloatingLabel controlId="floatingTextarea2" label="Comments" className="mb-3">
              <Form.Control as="textarea" name='message' placeholder="Leave a comment here"style={{ height: '100px' }}/>
          </FloatingLabel>
          <button type="submit" className="contactteam button-50">Submit</button>
      </Form>
      <ToastContainer/>
   </div>
  );
};

export default Contactform;