import Banner from "../Components/bannercarousel";
import Services from "../Components/servicecard.js";
import Contact from "../Components/contact.js";
import Gallery from "./gallery.js"
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// import '../animation.js'

function Home() {
    return (
       <section className="containtfixed">
         <Container className="bannercontainer" fluid>
            <Banner/>
        </Container>
        <div className="aboutus" id="ourmission">
        <Container>
            <Row>
                <h5 className="H5">Our Mission</h5>
                <Col>
                    <article>
                        <p>
                        At Saintes, our mission is to provide innovative solutions and expert guidance to navigate the ever-evolving landscape of education. We strive to foster academic excellence, promote inclusivity, and inspire lifelong learning in individuals and communities worldwide.
                        We assist students in identifying their academic and career goals, exploring educational opportunities, and navigating the college admissions process. From selecting the right courses to crafting compelling application essays, we provide personalized support every step of the way.

                       </p>
                    </article>
                </Col>
                
            </Row>
        </Container>
        </div>

        <div className="about heading" id="aboutus">
        <Container>
          <Row>
            <Col className="col-12 col-lg-5 col">
                <h5 className="H5">About Us.</h5>
                <h6>Education towards Elevation!</h6>
              <article>
                 <p className="serviesh6">
                    For over a decade, the founders of Saintes, have dedicated to empower students to achieve their highest potential. With a team of experienced professionals and a passion for education, we offer comprehensive consulting services tailored to meet the unique needs of each student.
                    We assist students in identifying their academic and career goals, exploring educational opportunities, and navigating the college admissions process. From selecting the right courses to crafting compelling application essays, we provide personalized support every step of the way.

                 </p>
                 <h6 className="download">Download University brochure Now</h6>
                 <a href="../assets/brochure.pdf" className="downloadfile" download>Download Here! <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708z"/>
</svg></a>
              </article>
              {/* <button className="button-50" role="button">Contact Us</button> */}
              </Col>
              <Col className="contactcontainer videoplayer aboutcontact">
              <div class="embed-responsive embed-responsive-16by9">
  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/xuLg09yGkLk" title="Saintes Education Consultants for More Videos" frameborder="0" allowfullscreen></iframe>
</div>
              <a href="https://www.youtube.com/@SaintesEdu" className="subcribe">
              <h5 className="H5"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-youtube" viewBox="0 0 16 16">
  <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.01 2.01 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.01 2.01 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31 31 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.01 2.01 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A100 100 0 0 1 7.858 2zM6.4 5.209v4.818l4.157-2.408z"/>
</svg> Subscribe <b>@ Saintes Education Consultants for More Videos</b></h5>
 <span className="morevideos">Click Here for More Videos</span>
              </a>
             </Col>
            </Row>
        </Container>
        </div>
        <div className="servies heading" id="whatwedo">
        <Container>
            <Row>
            <Col className="col-12 col-lg-5 sticky_header">
                <h5 className="H5">What we do?</h5>
                <h6>We offer premier services where excellence meets affordability.</h6>
                <Col>
                    <article>
                        <p className="serviesh6">
                        At Saintes, we specialize in College Admissions Counseling, Career Counseling, Academic Tutoring, Guidance workshops, College Placement Services and International Student Services. Our dedicated team works tirelessly to deliver exceptional services tailored to meet the unique needs of our clients. Here's what we do
                        </p>
                        <a href="#contact" className="button-50" role="button">Contact Us</a>
                    </article>
                </Col>
            </Col>
          
                <Services/>
            </Row>
        </Container>
        </div>
        <div className="servies heading" id="gallery">
        <Container>
         
            <h5 className="H5">Gallery</h5>
            <h6>Welcome to Our Gallery</h6>
                <Gallery/>
        </Container>
        </div>
      
           
          

        <div className="contact heading" id="contact">
        <Container>
            <Row>
            <Col className="col-12 col-lg-5">
                <h5 className="H5">Contact Us</h5>
                <h6>Ready to embark on your educational journey with us?</h6>
                <Col>
                    <article>
                        <p className="serviesh6">
                        Contact us today to learn more about our consulting services and how we can help you achieve your academic and professional goals.
                        </p>
                        <div className="_darkblue navbar-nav">
                            <a  href="mailto:contact@saintes.in" data-rr-ui-event-key="#deets" className="nav-link"><svg
                            xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-envelope-at-fill"
                            viewBox="0 0 16 16">
                            <path
                                d="M2 2A2 2 0 0 0 .05 3.555L8 8.414l7.95-4.859A2 2 0 0 0 14 2zm-2 9.8V4.698l5.803 3.546zm6.761-2.97-6.57 4.026A2 2 0 0 0 2 14h6.256A4.5 4.5 0 0 1 8 12.5a4.49 4.49 0 0 1 1.606-3.446l-.367-.225L8 9.586zM16 9.671V4.697l-5.803 3.546.338.208A4.5 4.5 0 0 1 12.5 8c1.414 0 2.675.652 3.5 1.671">
                            </path>
                            <path
                                d="M15.834 12.244c0 1.168-.577 2.025-1.587 2.025-.503 0-1.002-.228-1.12-.648h-.043c-.118.416-.543.643-1.015.643-.77 0-1.259-.542-1.259-1.434v-.529c0-.844.481-1.4 1.26-1.4.585 0 .87.333.953.63h.03v-.568h.905v2.19c0 .272.18.42.411.42.315 0 .639-.415.639-1.39v-.118c0-1.277-.95-2.326-2.484-2.326h-.04c-1.582 0-2.64 1.067-2.64 2.724v.157c0 1.867 1.237 2.654 2.57 2.654h.045c.507 0 .935-.07 1.18-.18v.731c-.219.1-.643.175-1.237.175h-.044C10.438 16 9 14.82 9 12.646v-.214C9 10.36 10.421 9 12.485 9h.035c2.12 0 3.314 1.43 3.314 3.034zm-4.04.21v.227c0 .586.227.8.581.8.31 0 .564-.17.564-.743v-.367c0-.516-.275-.708-.572-.708-.346 0-.573.245-.573.791">
                            </path>
                        </svg> contact@saintes.in</a>
                        <a href="tel:7034241124" data-rr-ui-event-key="2" className="nav-link"><svg
                            xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-phone-fill"
                            viewBox="0 0 16 16">
                            <path
                                d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0">
                            </path>
                        </svg> +91 7034241124</a>
                        
                        <a href="#memes" data-rr-ui-event-key="2" className="nav-link">
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-geo-alt-fill" viewBox="0 0 16 16">
  <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10m0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6"/>
</svg>
No. 32, Door No. 63/700, D Space, 6th Floor,  Sky Tower, Opposite Hyson Heritage, Bank Road, Kozhikode-673001</a>
                        </div>

                        
                    </article>

                    <div className="footer-social-icon">
                                {/* <span>Follow us</span> */}
                                <a href="https://www.facebook.com/profile.php?id=61557282710317" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951"/>
                                    </svg>
                                </a>
                                <a href="https://twitter.com/saintesedco"  target="_blank">
                                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" className="bi bi-twitter" viewBox="0 0 32 32" version="1.1">
                                        <g id="surface1"><rect x="0" y="0" width="32" height="32"/> <path d="M 12.003906 6.125 L 5.1875 6.125 L 13.265625 16.796875 L 5.703125 25.75 L 9.199219 25.75 L 14.917969 18.980469 L 19.996094 25.6875 L 26.8125 25.6875 L 18.496094 14.707031 L 18.511719 14.722656 L 25.671875 6.25 L 22.175781 6.25 L 16.859375 12.542969 Z M 8.949219 7.992188 L 11.070312 7.992188 L 23.050781 23.820312 L 20.929688 23.820312 Z M 8.949219 7.992188 "/></g>
                                </svg>
                                </a>
                                <a href="https://www.instagram.com/sainteseducation/"  target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-instagram" viewBox="0 0 16 16">
                                      <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.9 3.9 0 0 0-1.417.923A3.9 3.9 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.9 3.9 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.9 3.9 0 0 0-.923-1.417A3.9 3.9 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599s.453.546.598.92c.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.5 2.5 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.5 2.5 0 0 1-.92-.598 2.5 2.5 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233s.008-2.388.046-3.231c.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92s.546-.453.92-.598c.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92m-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217m0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334"/>
                                    </svg>
                                </a>

                                <a href="https://www.linkedin.com/in/saintes-education-consultants-1721212b8"  target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                      <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                    </svg>
                                </a>
                            </div>
                
                </Col>
            </Col>
             <Col className="contactcontainer">
             
             <Contact/>
             </Col>
                
            </Row>
        </Container>
        </div>

        <div className="about heading" id="ourteam">
        <Container>
          <Row>
            
          <Col className="col-12 col-lg-5">
              <h5 className="H5">Our Team</h5>
                <h6>Exploring the Visionaries Behind Innovation and Progress!</h6>
               <p className="serviesh6">At Saintes, we are driven by a diverse and talented team dedicated to excellence in everything we do. Together We are committed to excellence in everything we do, striving to exceed expectations and achieve meaningful results for our students.Get to know the faces behind our success.</p>
               {/* <p className="serviesh6">Contact us today to learn more about our consulting services and how we can help you achieve your academic and professional goals.</p> */}
             
             </Col>
             <Col className="row">   
              <div className="user-card-designer">
                  <div className="user-svg-wrapper">
                    <img className="founder" src="assets/Profile-0.webp" alt="team" />
                    </div> 
                    <div className="user-card-intro">
                        <h3>Dr. Aishwarya Gopinathan</h3>
                        <p>My team brings a wealth of experience and expertise in education, counseling, curriculum development, and institutional leadership</p>
                        {/* <div className="card-link">
                            <a className="socialmedia"  href="#">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                </svg>
                            </a>
                            <a className="socialmedia"  href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                </svg>
                            </a>
                        </div> */}
                    </div>
                   
               </div>
               <div className="user-card-designer">
                  <div className="user-svg-wrapper">
                    <img className="founder" src="assets/Profile-2.webp" alt="team" />
                    </div> 
                    <div className="user-card-intro">
                        <h3>Mr. M. Gopinathan</h3>
                        <p>I believe in the power of personalized guidance and tailor our services to meet the individual needs and goals of each client
</p>
{/* <div className="card-link">
                            <a className="socialmedia"  href="https://twitter.com/sagarpankan">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                </svg>
                            </a>
                            <a className="socialmedia"  href="https://www.linkedin.com/feed/?trk=nav_responsive_tab_home">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                </svg>
                            </a>
                        </div> */}
                    </div>
                   
               </div>
               <div className="user-card-designer">
                  <div className="user-svg-wrapper">
                    <img className="founder" src="assets/Profile-1.webp" alt="team" />
                    </div> 
                    <div className="user-card-intro">
                        <h3>Mr. Sagar Raj</h3>
                        <p>I stay abreast of emerging trends and best practices in education, leveraging innovative strategies and technologies to deliver impactful solutions.</p>
                        {/* <div className="card-link">
                            <a className="socialmedia"  href="https://twitter.com/sagarpankan">
                                 <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                                <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                                </svg>
                            </a>
                            <a className="socialmedia"  href="https://www.linkedin.com/feed/?trk=nav_responsive_tab_home">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-linkedin" viewBox="0 0 16 16">
                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z"/>
                                </svg>
                            </a>
                        </div> */}
                    </div>
                   
               </div>
              </Col>
             
            </Row>
        </Container>
        </div>

      
       </section>
    )
}

export default Home;